import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OpenEye from "../../image-assets/Eye.svg";
import "./ResetPassword.css";
import { MainRoundedButton } from "../../Styles-Elements/Buttons";
import { Textfield, InputWrapper } from "../../Styles-Elements/Inputs";
import { Subheading, LargeBody, Body } from "../../Styles-Elements/Labels";
import * as Colors from "../../Styles-Elements/Colors";
import visibilityIcon from "../../image-assets/visibilityicon.svg";
import stars5Icon from "../../image-assets/5stars.svg";
import teamMemberImg from "../../image-assets/team1.png";
import { stringData } from "../../redux/reducers/localString";
import { useSelector, useDispatch } from "react-redux";
import { Boxed } from "../../Styles-Elements/Box";
import globalRequest from "../../GlobalModule/globalRequest";
import { REQUEST } from "../../Helpers/Enums";
import closePopUpByKey from "../../GlobalModule/closePopUpByKey";
import focus from "../../GlobalModule/focus";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";

const ResetPassword = () => {
  let strings = useSelector(stringData);
  let dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState("Password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("Password");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const params = useParams();
  const [isForgotPasswordCalled, setIsForgotPasswordCalled] = useState(false);

  const checkForgotCode = async () => {
    let res = await globalRequest(
      "post",
      REQUEST.CHECK_FORGOT_PASSWORD,
      {
        userId: params.user_id,
        code: params.code,
      },
      {},
      false
    );
    let data = res.data;
    if (!data.ack) {
      dispatch(
        setSnackbar({
          snackbarMessage: data.msg,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
      history.push("/login");
    }
  };

  useEffect(() => {
    if (params.user_id && params.code && !isForgotPasswordCalled) {
      checkForgotCode();
      setIsForgotPasswordCalled(true);
    }
  }, [params, isForgotPasswordCalled]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("code", params.code);
    formData.append("user_id", params.user_id);
    formData.append("password", password);

    dispatch(changeLoader(true));
    const response = await globalRequest("post", REQUEST.RESET_PASSWORD_REQUEST, formData, {}, true);

    let res = response.data;
    if (res.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      history.push("/login");
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (password === "" && confirmPassword === "") {
      setIsDisabled(true);
    } else if (password !== confirmPassword) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [password, confirmPassword]);

  return (
    <div className="page-background" style={{ minHeight: "100%", backgroundColor: "#fff" }}>
      {/* <Header /> */}
      <form onSubmit={handleSubmit} className="" style={{ marginBottom: "0px" }}>
        <Boxed display={"flex"} style={{ height: "100vh" }}>
          <div className="login-signup-left">
            <div className="">
              <Boxed className="login-head-box" margin={"0 0 40px 0"}>
                <div className="login-head">{strings.letsResetYourPassword}</div>
                <Subheading
                  text={strings.youreAlmostThere}
                  color={Colors.black}
                  textAlign={"center"}
                  margin={"8px 0 12px 0"}
                />
                <Body
                  text={strings.completeTheDetailsBelowAndClickReset}
                  color={Colors.darkGray}
                  textAlign={"center"}
                  margin={"0 0 0 0"}
                />
              </Boxed>
              <Boxed>
                <div className="grid-item-flex gif-dd4">
                  <Subheading text={strings.enterNewPassword} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
                </div>
                <div className="grid-item">
                  <InputWrapper>
                    <Textfield
                      placeholder={strings.enterNewPassword}
                      type={showPassword}
                      value={password}
                      required={true}
                      onChange={(event) => setPassword(event.target.value)}
                      padding={"12px 52px 12px 16px"}
                    />
                    <img
                      src={passwordVisible ? OpenEye : visibilityIcon}
                      alt="open-and-close-eye"
                      onClick={() => {
                        setPasswordVisible(!passwordVisible);
                        setShowPassword(showPassword == "Password" ? "text" : "Password");
                      }}
                      className="openeyeicon"
                    />
                  </InputWrapper>
                </div>

                <div className="grid-item-flex gif-dd4">
                  <Subheading text={strings.confirmNewPassword} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
                </div>

                <div className="grid-item">
                  <InputWrapper>
                    <Textfield
                      placeholder={strings.confirmNewPassword}
                      type={showConfirmPassword}
                      value={confirmPassword}
                      required={true}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                      padding={"12px 52px 12px 16px"}
                    />
                    <img
                      src={confirmPasswordVisible ? OpenEye : visibilityIcon}
                      alt="open-and-close-eye"
                      onClick={() => {
                        setConfirmPasswordVisible(!confirmPasswordVisible);
                        setShowConfirmPassword(showConfirmPassword == "Password" ? "text" : "Password");
                      }}
                      className="openeyeicon"
                    />
                  </InputWrapper>
                </div>
                <div className="grid-item">
                  <MainRoundedButton
                    text={strings.resetMyPassword}
                    margin={"24px 0 24px 0"}
                    type={"submit"}
                    disabled={isDisabled}
                  />
                </div>
              </Boxed>
            </div>
          </div>
          <div className="login-signup-right">
            <img
              src={stars5Icon}
              alt=""
              className="width116"
              style={{ margin: "auto", marginTop: "0px", marginBottom: "50px" }}
            />
            <Boxed className="first-box">
              <Subheading
                text={strings.canNotImagineMyDirectSourcingLifeWithoutIt}
                color={Colors.mainLight}
                textAlign={"center"}
                fontStyle={"italic"}
                padding={"0 0px 50px 0px"}
              />
              <div style={{ textAlign: "center", marginBottom: "50px" }}>
                <img src={teamMemberImg} alt="" className="team-user-icon" style={{ margin: "auto" }} />
              </div>
              <LargeBody
                text={"Nathan Zimmerman"}
                color={Colors.mainLight}
                fontWeight={"600"}
                textAlign={"center"}
                padding={"0 0px 4px 0px"}
                className=""
              />
              <Body
                text={"Senior Partner, High Country Search"}
                color={Colors.mainLight}
                textAlign={"center"}
                padding={"0 0px 0px 0px"}
                className=""
              />
            </Boxed>
          </div>
        </Boxed>
      </form>
    </div>
  );
};

export default ResetPassword;
