import "antd/dist/antd.css";
import * as Colors from "../../Styles-Elements/Colors";
import { Subheading, Heading3SemiBold, Bold, Heading2Bold, SubheadingBold, Body } from "../../Styles-Elements/Labels";
import booHappy from "../../image-assets/boo-happy.png";
import checkCircleBroken from "../../image-assets/check-circle-broken.svg";
import { Boxed } from "../../Styles-Elements/Box";
import Scrollbar from "react-smooth-scrollbar";
import strings from "../../localisation_en.json";
import { useSelector } from "react-redux";
import { currentTrial } from "../../redux/reducers/getTrial";
import { currentCredit } from "../../redux/reducers/getCredit";
import SignupDetails from "./SignupDetails";
import AddProfile from "./AddProfile";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import formatDate from "../../GlobalModule/formatDate";
import './signUpDetails.css';
import "react-phone-input-2/lib/style.css";

const SignupDetailsModal = () => {
  const creditData = useSelector(currentCredit);
  const trialData = useSelector(currentTrial);
  const auth = useSelector(tooledUpLocalData);

  return (
    <div className="container">
      <div className="container-modal ftam">
        <Scrollbar className="container-modal-mid">
          <Boxed className="trail-account-holder">
            <div className="trail-account-left">{auth?.access === "owner" ? <SignupDetails /> : <AddProfile />}</div>
            <div className="trail-account-right">
              <Heading3SemiBold
                text={strings.yourFreeTrialAccountIsNowActiveForUpTo5Users}
                color={Colors.mainLight}
                padding={"0 0 32px 0"}
              />
              <div className="free-token">
                <Heading2Bold text={"FREE"} fontSize={"40px"} color={Colors.nightPurple} padding={"0 0 0px 0"} />
                <Bold
                  text={strings.getTokensAndEnjoyTheBenefitsForDays}
                  color={Colors.nightPurple}
                  padding={"0 10px 0px 24px"}
                />
                <div
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    backgroundColor: "#00E4A2",
                  }}
                >
                  <img src={booHappy} alt="" style={{ width: "60px", height: "60px" }} />
                </div>
              </div>
              <Subheading text={"Your free trial features include: "} color={"#fff"} padding={"0 0 16px 0"} />
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 16px 0px"}>
                <Boxed display={"flex"} alignItems={"center"}>
                  <img src={checkCircleBroken} alt="" />
                  <Subheading text={strings.buildFeature} color={"#fff"} padding={"0 0 0px 12px"} />
                </Boxed>
                <SubheadingBold text={`${creditData?.auto_build} Tokens`} color={"#fff"} padding={"0 0 0px 0"} />
              </Boxed>
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 16px 0px"}>
                <Boxed display={"flex"} alignItems={"center"}>
                  <img src={checkCircleBroken} alt="" />
                  <Subheading text={strings.enrichFeature} color={"#fff"} padding={"0 0 0px 12px"} />
                </Boxed>
                <SubheadingBold text={`${creditData?.auto_enrich} Tokens`} color={"#fff"} padding={"0 0 0px 0"} />
              </Boxed>
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 16px 0px"}>
                <Boxed display={"flex"} alignItems={"center"}>
                  <img src={checkCircleBroken} alt="" />
                  <Subheading text={strings.translationFeature} color={"#fff"} padding={"0 0 0px 12px"} />
                </Boxed>
                <SubheadingBold text={`${creditData?.translations} Tokens`} color={"#fff"} padding={"0 0 0px 0"} />
              </Boxed>
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"80px 0px 0px 0px"}>
                <Body text={strings.yourFreeTrialPeriodWillExpireOn} color={"#fff"} padding={"0 0 0px 0"} />
                <Bold text={formatDate(trialData?.expire_on)} color={"#fff"} padding={"0 0 0px 4px"} />
              </Boxed>
            </div>
          </Boxed>
        </Scrollbar>
      </div>
    </div>
  );
};
export default SignupDetailsModal;
