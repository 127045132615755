import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import {
  Heading2Bold,
  Body,
  Heading3,
  LargeBody,
  LargeBodyBold,
  LargeSmall,
  Subheading,
  SubheadingBold,
} from "../Styles-Elements/Labels";
import { CreateButton } from "../Styles-Elements/Buttons";
import closeWhite from "../image-assets/close-white.png";
import rocketPurple from "../image-assets/rocket-purple.png";
import checkCircleBroken from "../image-assets/check-circle-broken.svg";
import crossCircleBroken from "../image-assets/red-circle.svg";
import booGradient from "../image-assets/boo-gradient.png";
import { Boxed } from "../Styles-Elements/Box";
import Scrollbar from "react-smooth-scrollbar";
import plusIcon from "../image-assets/plus-icon.svg";
import minusIcon from "../image-assets/minus-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setData, tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { changeTrial } from "../redux/reducers/getTrial";
import { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { NEW_PLAN_ID, PLAN_AMOUNT, PLAN_TYPE, REQUEST, STORAGE } from "../Helpers/Enums";
import { ENVIROMENT, VENDOR_ID } from "../Helpers/Payments";
import { currentAmount, setPaddleAmount } from "../redux/reducers/paddleAmount";
import { currentDiscountAmount, setDiscountAmount } from "../redux/reducers/discountAmount";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../GlobalModule/globalRequest";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";
import { stringData } from "../redux/reducers/localString";
import getTrialData from "../GlobalModule/getTrialData";
import "./child.css";

let actualAmount = null;

const ManagementPlanSelection = ({ closePopup }) => {
  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const strings = useSelector(stringData);
  const [step, setStep] = useState(0);
  const [plan, setPlan] = useState("annual");
  const [seat, setSeat] = useState(1);
  let [realAmount, setRealAmount] = useState(0);
  let currentPaddleAmount = useSelector(currentAmount);
  let currentDisAmount = useSelector(currentDiscountAmount);

  useEffect(() => {
    let amounts = plan == PLAN_TYPE.MONTHLY ? PLAN_AMOUNT.MOST_NEW_ONE_1.MONTHLY : PLAN_AMOUNT.MOST_NEW_ONE_1.YEARLY;
    setRealAmount(parseFloat(seat * amounts).toFixed(2));
  }, [seat, plan]);

  const TimeToJoin = () => {
    return (
      <div>
        <Boxed display={"flex"} alignItems={"start"}>
          <div>
            <Heading3 text={"It’s time to join the"} color={Colors.mainLight} padding={"0 0 0px 0"} />
            <Boxed display={"flex"} alignItems={"center"}>
              <Heading3 text={"Raccoon"} color={Colors.sharpGreen} padding={"0 4px 0px 0"} />
              <Heading3 text={"Den."} color={Colors.mainLight} padding={"0 0 0px 0"} />
            </Boxed>
            <LargeBody
              text={strings.upgradeTodayAndBenefitFromUnlimitedUsageOfAllCurrentFeaturesIncluding}
              fontSize={"40px"}
              color={Colors.mainLight}
              padding={"16px 0 0px 0"}
            />
          </div>
          <img src={booGradient} className="boo-g-128" style={{ marginTop: "-8px" }} alt="" />
        </Boxed>
        <LargeBodyBold text={""} color={Colors.sharpGreen} padding={"24px 0 24px 0"} textAlign={"right"} />
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 28px 0px"}>
          <Boxed display={"flex"} alignItems={"center"}>
            <img src={checkCircleBroken} alt="" />
            <LargeBody text={strings.aiPoweredBooleanBuilder} color={"#fff"} padding={"0 0 0px 12px"} />
          </Boxed>
        </Boxed>
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 28px 0px"}>
          <Boxed display={"flex"} alignItems={"center"}>
            <img src={checkCircleBroken} alt="" />
            <LargeBody text={strings.autoEnrichSuggestions} color={"#fff"} padding={"0 0 0px 12px"} />
          </Boxed>
        </Boxed>
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 28px 0px"}>
          <Boxed display={"flex"} alignItems={"center"}>
            <img src={checkCircleBroken} alt="" />
            <LargeBody text={strings.multiLanguageTranslation} color={"#fff"} padding={"0 0 0px 12px"} />
          </Boxed>
        </Boxed>
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 28px 0px"}>
          <Boxed display={"flex"} alignItems={"center"}>
            <img src={checkCircleBroken} alt="" />
            <LargeBody text={strings.generateSearchCombinations} color={"#fff"} padding={"0 0 0px 12px"} />
          </Boxed>
        </Boxed>
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0px 28px 0px"}>
          <Boxed display={"flex"} alignItems={"center"}>
            <img src={plan === "monthly" ? crossCircleBroken : checkCircleBroken} alt="" />
            <LargeBody text={strings.sourcingAcademy} color={"#fff"} padding={"0 0 0px 12px"} />
          </Boxed>
          <LargeBodyBold text={plan === "monthly" ? "Not included" : ""} color={"#fff"} padding={"0 0 0px 0"} />
        </Boxed>
        <CreateButton
          text={strings.confirmUpgrade}
          color={Colors.mainLight}
          className={"create-button-green height48"}
          margin={"19px 0 0 0"}
          onClick={() => {
            if (seat) {
              setStep(1);
            } else {
              dispatch(
                setSnackbar({
                  snackbarMessage: "No of seat cannot be null in order to continue",
                  snackbarOpen: true,
                  snackbarState: "error",
                })
              );
            }
          }}
        />
      </div>
    );
  };

  const StaticAmountPage = () => {
    return (
      <div>
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0 20px 0"}>
          <Heading3 text={strings.orderSummary} color={Colors.darkPurple} padding={"0 0 0px 0"} />
          <div className="blue-border-btn" onClick={() => setStep(0)}>
            {strings.changePlan}
          </div>
        </Boxed>
        <div>
          <LargeBody text={strings.numberOfSeats} fontWeight={"600"} color={Colors.nightGray} padding={"0 0 20px 0"} />
          <Boxed className="counter-holder">
            <button className="counter-button" disabled={true}>
              <img src={minusIcon} width={16} alt="" />
            </button>
            <input value={seat} className="user-count-input" disabled={true} />
            <button className="counter-button" disabled={true}>
              <img src={plusIcon} width={16} alt="" />
            </button>
          </Boxed>
          {plan == "annual" ? (
            <div className="mps-plan-box background-border">
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} className="mps-heading">
                <Boxed display={"flex"} alignItems={"center"}>
                  <img src={rocketPurple} style={{ width: "32px" }} alt="" />
                  <LargeBody
                    text={strings.billedAnnually}
                    textTransform={"Capitalize"}
                    fontWeight={"600"}
                    color={"#344054"}
                    padding={"0 0 0px 14px"}
                  />
                </Boxed>
                <Checkbox checked={true} />
              </Boxed>
              <Boxed padding={"20px 16px"}>
                <Boxed display={"flex"} alignItems={"start"} justifyContent={"space-between"}>
                  <Boxed display={"flex"} alignItems={"center"}>
                    <Heading2Bold
                      text={`£${PLAN_AMOUNT.MOST_NEW_ONE_1.YEARLY / 12}`}
                      fontWeight={"600"}
                      color={"#344054"}
                      padding={"0 0 0px 0"}
                    />
                    <Body text={"per month, per seat"} color={"#344054"} padding={"0 0 0px 4px"} />
                  </Boxed>
                  <Boxed display={"flex"} alignItems={"center"} justifyContent={"center"} className="discount-save">
                    <span
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#17B26A",
                        display: "flex",
                        marginRight: "5px",
                      }}
                    ></span>
                    <LargeSmall text={strings.discountString} color={"#344054"} padding={"0 0 0px 0px"} />
                  </Boxed>
                </Boxed>
                <Body text={strings.includesAccessToAllCurrentFeatures} color={"#344054"} padding={"0 0 0px 0"} />
              </Boxed>
            </div>
          ) : (
            <div className="mps-plan-box">
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} className="mps-heading">
                <Boxed display={"flex"} alignItems={"center"}>
                  <img src={rocketPurple} style={{ width: "32px" }} alt="" />
                  <LargeBody
                    text={strings.billedMonthly}
                    textTransform={"Capitalize"}
                    fontWeight={"600"}
                    color={"#344054"}
                    padding={"0 0 0px 14px"}
                  />
                </Boxed>
                <Checkbox checked={true} />
              </Boxed>

              <Boxed padding={"20px 16px"}>
                <Boxed display={"flex"} alignItems={"center"}>
                  <Heading2Bold
                    text={`£${PLAN_AMOUNT.MOST_NEW_ONE_1.MONTHLY}`}
                    fontWeight={"600"}
                    color={"#344054"}
                    padding={"0 0 0px 0"}
                  />
                  <Body text={"per month, per seat"} color={"#344054"} padding={"0 0 0px 4px"} />
                </Boxed>
                <Body text={strings.includesLimitedAccess} color={"#344054"} padding={"0 0 0px 0"} />
              </Boxed>
            </div>
          )}

          <Boxed className="subtotal-discount-box" margin={"28px 0 0 0"}>
            <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Subheading text={strings.subtotal} fontWeight={"600"} color={Colors.nightGray} padding={"0 0 0px 0"} />
              <Subheading text={`£${realAmount}`} fontWeight={"600"} color={Colors.nightGray} padding={"0 0 0px 0"} />
            </Boxed>
            <div className="hr"></div>
            {currentDisAmount !== null ? (
              <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Subheading text={strings.discountApplied} color={Colors.nightGray} padding={"0 0 0px 0"} />
                <Subheading text={`-£${currentDisAmount}`} color={Colors.nightGray} padding={"0 0 0px 0"} />
              </Boxed>
            ) : null}

            <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Subheading text={strings.tax} color={Colors.nightGray} padding={"0 0 0px 0"} />
              <Subheading text={`£${currentPaddleAmount?.tax}`} color={Colors.nightGray} padding={"0 0 0px 0"} />
            </Boxed>
            <div className="hr"></div>
            <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <SubheadingBold text={strings.dueToday} color={Colors.nightGray} padding={"0 0 0px 0"} />
              <SubheadingBold
                text={`£${(currentPaddleAmount?.grandTotal).toFixed(2)}`}
                color={Colors.nightGray}
                padding={"0 0 0px 0"}
              />
            </Boxed>
          </Boxed>
        </div>
      </div>
    );
  };

  const RealAmountPage = () => {
    return (
      <div>
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0 20px 0"}>
          <Heading3 text={strings.selectAPlan} color={Colors.darkPurple} padding={"0 0 0px 0"} />
          <div className="blue-border-btn" onClick={closePopup}>
            Back
          </div>
        </Boxed>
        <div>
          <LargeBody
            text={strings.howManySeatsWouldYouLikeToPurchase}
            fontWeight={"600"}
            color={Colors.nightGray}
            padding={"0 0 20px 0"}
          />
          <Boxed className="counter-holder">
            <button
              className="counter-button"
              onClick={() => {
                if (seat > 1) {
                  setSeat((seat) => seat - 1);
                } else {
                  setSeat(1);
                }
              }}
            >
              <img src={minusIcon} width={16} alt="" />
            </button>
            <input
              value={seat}
              autoFocus
              className="user-count-input"
              onChange={(e) => {
                let v = e.target.value;
                if (v != "") {
                  if (!isNaN(v)) {
                    setSeat(parseInt(v));
                  }
                } else {
                  setSeat("");
                }
              }}
            />
            <button
              className="counter-button"
              onClick={() => {
                setSeat((seat) => seat + 1);
              }}
            >
              <img src={plusIcon} width={16} alt="" />
            </button>
          </Boxed>
          {/* background border class for checked whole section bg active */}
          <div className="mps-plan-box background-border">
            <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} className="mps-heading">
              <Boxed display={"flex"} alignItems={"center"}>
                <img src={rocketPurple} style={{ width: "32px" }} alt="" />
                <LargeBody
                  text={strings.billedAnnually}
                  textTransform={"Capitalize"}
                  fontWeight={"600"}
                  color={"#344054"}
                  padding={"0 0 0px 14px"}
                />
              </Boxed>
              <Checkbox
                value={"annual"}
                checked={plan === "annual" ? true : false}
                onChange={() => setPlan("annual")}
              />
            </Boxed>
            <Boxed padding={"20px 16px"}>
              <Boxed display={"flex"} alignItems={"start"} justifyContent={"space-between"}>
                <Boxed display={"flex"} alignItems={"center"}>
                  <Heading2Bold
                    text={`£${PLAN_AMOUNT.MOST_NEW_ONE_1.YEARLY / 12}`}
                    fontWeight={"600"}
                    color={"#344054"}
                    padding={"0 0 0px 0"}
                  />
                  <Body text={"per month, per seat"} color={"#344054"} padding={"0 0 0px 4px"} />
                </Boxed>
                <Boxed display={"flex"} alignItems={"center"} justifyContent={"center"} className="discount-save">
                  <span
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      backgroundColor: "#17B26A",
                      display: "flex",
                      marginRight: "5px",
                    }}
                  ></span>
                  <LargeSmall text={strings.discountString} color={"#344054"} padding={"0 0 0px 0px"} />
                </Boxed>
              </Boxed>
              <Body text={strings.includesAccessToAllCurrentFeatures} color={"#344054"} padding={"0 0 0px 0"} />
            </Boxed>
          </div>
          <div className="mps-plan-box">
            <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} className="mps-heading">
              <Boxed display={"flex"} alignItems={"center"}>
                <img src={rocketPurple} style={{ width: "32px" }} alt="" />
                <LargeBody
                  text={strings.billedMonthly}
                  textTransform={"Capitalize"}
                  fontWeight={"600"}
                  color={"#344054"}
                  padding={"0 0 0px 14px"}
                />
              </Boxed>
              <Checkbox
                value={"monthly"}
                checked={plan === "monthly" ? true : false}
                onChange={() => setPlan("monthly")}
              />
            </Boxed>
            <Boxed padding={"20px 16px"}>
              <Boxed display={"flex"} alignItems={"center"}>
                <Heading2Bold
                  text={`£${PLAN_AMOUNT.MOST_NEW_ONE_1.MONTHLY}`}
                  fontWeight={"600"}
                  color={"#344054"}
                  padding={"0 0 0px 0"}
                />
                <Body text={"per month, per seat"} color={"#344054"} padding={"0 0 0px 4px"} />
              </Boxed>
              <Body text={strings.includesLimitedAccess} color={"#344054"} padding={"0 0 0px 0"} />
            </Boxed>
          </div>
        </div>
      </div>
    );
  };

  const eventCallback = (data) => {
    if (data.event == "Checkout.Payment.Selection") {
      let customer = data.eventData.checkout.prices.customer;
      actualAmount = customer;
      dispatch(
        setPaddleAmount({
          total: customer?.total,
          tax: customer?.total_tax,
          grandTotal: parseFloat(customer?.total),
          isDiscountApplied: false,
        })
      );
      dispatch(setDiscountAmount(null));
    }
  };

  useEffect(() => {
    if (step === 1) {
      const Paddle = window?.Paddle;
      let product = plan === PLAN_TYPE.MONTHLY ? NEW_PLAN_ID.MONTHLY : NEW_PLAN_ID.YEARLY;

      if (ENVIROMENT) {
        Paddle.Environment.set("sandbox");
      }

      Paddle.Setup({
        vendor: VENDOR_ID,
        eventCallback: eventCallback,
      });

      Paddle.Checkout.open({
        method: "inline",
        email: auth?.email,
        product: product,
        country: auth?.country_short_code,
        postcode: auth?.postcode,
        quantity: seat,
        allowQuantity: false,
        passthrough: auth?.user_id,
        disableLogout: true,
        frameTarget: "payment-method-holder", // The className of your checkout <div>
        frameInitialHeight: 416,
        frameStyle: "width:100%; min-width:287px; background-color: transparent; border: none;",
        customData: { tolt_referral: window.tolt_referral },
        successCallback: function (data1) {
          var checkoutId = data1.checkout.id;
          let coupon = data1?.checkout?.coupon?.coupon_code;

          Paddle.Order.details(checkoutId, async (data2) => {
            let formData = new FormData();

            formData.append("user_id", data1?.checkout?.passthrough);
            formData.append("pay_id", data1?.user.id);
            formData.append("plan_id", data2?.order?.product_id);
            formData.append("subscription_id", data2?.order?.subscription_id);
            formData.append("total", data2?.order?.total);
            formData.append("receipt_url", data2?.order?.receipt_url);
            formData.append("total_user", data2?.order?.quantity);
            formData.append("plan_type", plan);
            formData.append("user_cancel", 0);
            formData.append("coupon_code", coupon);

            let url = REQUEST.CHECKOUT_REQUEST;
            const response = await globalRequest("post", url, formData, {}, false);
            let data = response.data;
            if (data.status == 1) {
              let d = {
                ...data.data,
                token: auth?.token,
                isSubscribed: true,
              };

              addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, d, "add", "single");
              dispatch(setData());

              dispatch(
                setSnackbar({
                  snackbarMessage: data?.message,
                  snackbarOpen: true,
                  snackbarState: "success",
                })
              );
              let trial = await getTrialData(auth?.access === "owner" ? auth.id : auth?.team_id);
              dispatch(changeTrial(trial));

              closePopup();
            } else {
              dispatch(
                setSnackbar({
                  snackbarMessage: data?.message,
                  snackbarOpen: true,
                  snackbarState: "error",
                })
              );
            }
          });
        },
      });
    } else {
      dispatch(
        setPaddleAmount({
          total: 0,
          tax: 0,
          grandTotal: 0,
          isDiscountApplied: false,
        })
      );
      dispatch(setDiscountAmount(null));
    }
  }, [step]);

  return (
    <div className="modal">
      <div className="ftam">
        <Scrollbar className="container-modal-mid">
          <img src={closeWhite} alt="" className="p-white-close" onClick={() => closePopup()} />
          <Boxed className="trail-account-holder">
            <div className="trail-account-left" style={{ width: "100%" }}>
              {step === 0 ? <RealAmountPage /> : <StaticAmountPage />}
            </div>
            <div className="trail-account-right" style={{ width: "100%" }}>
              {step === 0 ? <TimeToJoin /> : null}
              <div style={{ display: step === 1 ? "block" : "none" }}>
                <Heading3 text={strings.paymentMethod} color={Colors.mainLight} padding={"0 0 20px 0"} />
                <div className="checkout-details-holder">
                  <div className="payment-method-holder"></div>
                </div>
              </div>
            </div>
          </Boxed>
        </Scrollbar>
      </div>
    </div>
  );
};
export default ManagementPlanSelection;
