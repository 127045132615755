import "antd/dist/antd.css";
import * as Colors from "../../Styles-Elements/Colors";
import { Subheading } from "../../Styles-Elements/Labels";
import { Textfield } from "../../Styles-Elements/Inputs";
import { CreateButton } from "../../Styles-Elements/Buttons";
import { Select } from "antd";
import PhoneInput from "react-phone-input-2";
import strings from "../../localisation_en.json";
import { useEffect, useState } from "react";
import getAllCountry from "../../GlobalModule/getAllCountry";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { setPaddleAmount } from "../../redux/reducers/paddleAmount";
import { setDiscountAmount } from "../../redux/reducers/discountAmount";
import { NEW_MODULE_API, STORAGE } from "../../Helpers/Enums";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import parseMax from "libphonenumber-js/max";
import globalRequest from "../../GlobalModule/globalRequest";
import { setData, tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { currentTrial } from "../../redux/reducers/getTrial";
import "./signUpDetails.css";

const { Option } = Select;

const SignupDetails = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phoneDetail, setPhoneDetail] = useState({
    phone: "",
    countryCode: "",
    countryLocale: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [getAllCountryList, setGetAllCountryList] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [countryPostCode, setCountryPostCode] = useState("");
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);

  const dispatch = useDispatch();

  useEffect(() => {
    countries();
  }, []);

  let countries = async () => {
    let country = await getAllCountry();
    setGetAllCountryList(country);
  };

  const handleSelectCountry = (value, check) => {
    setSelectedCountryCode(value);
    setSelectedCountryId(check.key);
  };

  const isValidPhone = (number) => {
    if (number && phoneDetail) {
      var lengthStr = phoneDetail.countryCode.length;
      let pnumber = phoneDetail.phone.slice(lengthStr);
      if (pnumber) {
        const phoneNumber = parseMax(pnumber, phoneDetail.countryLocale.toUpperCase());
        return phoneNumber.isValid();
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const submit = async () => {
    if (
      firstName &&
      lastName &&
      isValidPhone(phoneNumber) &&
      company &&
      phoneDetail?.phone &&
      selectedCountryCode &&
      selectedCountryId &&
      countryPostCode
    ) {
      let userId = auth?.id;

      dispatch(changeLoader(true));

      const data = {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        company: company,
        countryCode: phoneDetail?.countryCode,
        phoneNumber: phoneDetail?.phone,
        countryFlag: phoneDetail?.countryLocale,
        countryShortCode: selectedCountryCode,
        zip: countryPostCode,
        countryId: selectedCountryId,
      };

      let response = await globalRequest("post", NEW_MODULE_API.SIGNUP_DETAILS, data, {}, false);

      let datas = response.data;
      if (datas.status == 1) {
        let dat = {
          token: auth?.token,
          ...datas.data,
        };

        addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, dat, "add", "single");
        dispatch(setData());
        dispatch(
          setSnackbar({
            snackbarMessage: datas.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
        dispatch(
          setPaddleAmount({
            total: 0,
            tax: 0,
            grandTotal: 0,
          })
        );
        dispatch(setDiscountAmount(null));
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: datas.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }

      dispatch(changeLoader(false));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.allFieldsMustBeFilled,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  return (
    <>
      <div>
        <div className="grid-colmn-equal user-detail-holder">
          <div>
            <Subheading text={strings.firstName} color={Colors.nightPurple} padding={"0 0 8px 0"} />
            <Textfield
              placeholder={strings.enterYourFirstName}
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
            />
          </div>
          <div>
            <Subheading text={strings.lastName} color={Colors.nightPurple} padding={"0 0 8px 0"} />
            <Textfield
              placeholder={strings.enterYourLastName}
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
            />
          </div>
        </div>
        <div className="user-detail-holder">
          <Subheading text={strings.company} color={Colors.nightPurple} padding={"0 0 8px 0"} />
          <Textfield
            placeholder={strings.company}
            onChange={(event) => setCompany(event.target.value)}
            value={company}
          />
        </div>
        <div className="user-detail-holder">
          <Subheading text={strings.phoneNumber} color={Colors.nightPurple} padding={"0 0 8px 0"} />
          <PhoneInput
            countryCodeEditable={false}
            country={"gb"}
            enableSearch={true}
            placeholder="Enter a Phone Number"
            value={phoneNumber}
            onChange={(phone, country) => {
              setPhoneDetail({
                phone: phone,
                countryCode: country.dialCode,
                countryLocale: country.countryCode,
              });
              setPhoneNumber(phone);
            }}
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
          />
        </div>
        <div className="grid-colmn-equal user-detail-holder">
          <div>
            <Subheading text={strings.postcode} color={Colors.nightPurple} padding={"0px 0 8px 0"} />
            <Textfield
              placeholder={strings.postcode}
              max="8"
              margin={"8px 0 0 0"}
              onChange={(event) => setCountryPostCode(event.target.value)}
              value={countryPostCode}
            />
          </div>
          <div>
            <Subheading text={strings.country} color={Colors.nightPurple} padding={"0px 0 8px 0"} />
            <Select
              showSearch={true}
              placeholder="Select Country"
              value={selectedCountryCode ? selectedCountryCode : null}
              onChange={handleSelectCountry}
              className="ss-select-box"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {getAllCountryList.map((option) => (
                <Option key={option.id} value={option.country_code}>
                  {option.country_name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <CreateButton
          text={strings.getStarted}
          color={Colors.mainLight}
          className={"create-button-green height48"}
          onClick={submit}
        />
      </div>
    </>
  );
};

export default SignupDetails;
