import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import 'antd/dist/antd.css'
import * as Colors from '../Styles-Elements/Colors'
import {
  Subheading,
  Subtitle,
  LargeBody,
} from '../Styles-Elements/Labels'
import { Textfield, InputWrapper } from '../Styles-Elements/Inputs'
import {
  ClearButton,
  MainRoundedButton,
} from '../Styles-Elements/Buttons'
import booNeutral from '../image-assets/boo-neutral.png'
import visibilityIcon from '../image-assets/visibilityicon.svg'

import { REQUEST, ACTION_TYPE } from '../Helpers/Enums'
import { makeStyles } from '@material-ui/core/styles'
import Scrollbar from 'react-smooth-scrollbar'
import OpenEye from '../image-assets/Eye.svg'
import closePopUpByKey from '../GlobalModule/closePopUpByKey'
import focus from '../GlobalModule/focus'
import { tooledUpLocalData } from '../redux/reducers/getTooledupData'
import { setSnackbar } from '../redux/reducers/snackbar'
import globalRequest from '../GlobalModule/globalRequest'
import { changeLoader } from '../redux/reducers/loader'
// Importing localised strings
const strings = require('../localisation_en.json')

function ResetPasswordPopUp(props) {

  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();

  const [inputFieldEntry, setInputFieldEntry] = useState({
    postCode: '',
    feedback: '',
    new: '',
    exist: '',
    newPwd: '',
    email: '',
    firstName: auth?.firstname,
    lastName: auth?.lastname,
  })
  const [showResetPassword, setShowResetPassword] = useState('Password')
  const [showVisibilityIconForReset, setShowVisibilityIconForReset] = useState(
    true,
  )
  const [resetCodeAndId, setResetCodeAndId] = useState({
    code: '',
    user_id: '',
  })

  useEffect(() => {
    // console.log(window.location.href)
    let url = new URL(window.location.href)
    let code = url.searchParams.get('code')
    let user_id = url.searchParams.get('user_id')
    if (code && user_id) {
      setResetCodeAndId({ code: code, user_id: user_id })
    }
  }, [])
  
  const handleInputChange = (e, clear) => {
    if (clear !== true) {
      setInputFieldEntry({
        ...inputFieldEntry,
        [e.currentTarget.name]: e.currentTarget.value,
      })
    } else {
      setInputFieldEntry({})
    }
  }

  function validateResetPwdInputs() {
    if (validPasswordCheck(inputFieldEntry.newPwd) === false) {

      dispatch(setSnackbar({
        snackbarMessage: strings.thePasswordMustHave,
        snackbarOpen: true,
        snackbarState: 'error'
      }))
      return false
    } else {
      return true
    }
  }

  const validPasswordCheck = (pass) => {
    let lowerCase = /[a-z]/g
    let upperCase = /[A-Z]/g
    let number = /[0-9]/g
    let specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g

    if (
      pass.match(lowerCase) &&
      pass.match(upperCase) &&
      pass.match(number) &&
      pass.match(specialChar) &&
      pass.length >= 7
    ) {
      return true
    } else {
      return false
    }
  }

  const resetPassword = async (e) => {
    e.preventDefault()
    if (inputFieldEntry.newPwd == null || inputFieldEntry.newPwd == '') {
      dispatch(setSnackbar({
        snackbarMessage: strings.enterNewPassword,
        snackbarOpen: true,
        snackbarState: 'success'
      }))
    } else if (validateResetPwdInputs()) {
      let formData = new FormData()
      formData.append('code', resetCodeAndId.code)
      formData.append('user_id', resetCodeAndId.user_id)
      formData.append('password', inputFieldEntry.newPwd)

      dispatch(changeLoader(true));
      const response = await globalRequest("post", REQUEST.RESET_PASSWORD_REQUEST, formData, {}, true);

      let res = response.data;
      if (res.status == 1) {
        setInputFieldEntry({ ...inputFieldEntry, newPwd: '' })
        dispatch(setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: 'success'
        }))
        props.closePopup()
      } else {
        setInputFieldEntry({ ...inputFieldEntry, newPwd: '' })
        dispatch(setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: 'error'
        }))
      }
      dispatch(changeLoader(false));
    }
  }

  useEffect(() => {
    focus("newPwd");
    closePopUpByKey(props.closePopup)
  }, [])

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.timeToResetYourPassword}
            color={Colors.nightPurple}
            textAlign={'center'}
            padding={'24px 0'}
          />
          <LargeBody
            text={strings.enterYourPasswordBelowAndYouAreGoodToGo}
            textAlign={'center'}
            color={Colors.darkGray}
            padding={'0 0 16px 0'}
          />
          <Subheading
            text={strings.password}
            color={Colors.nightPurple}
            padding={'0 0 8px 0'}
          />
          <InputWrapper>
            <Textfield
              name={'newPwd'}
              id="newPwd"
              value={inputFieldEntry.newPwd}
              type={showResetPassword}
              onChange={(e) => handleInputChange(e)}
              placeholder={strings.enterYourPassword}
              margin={'8px auto'}
              padding={'12px 52px 12px 16px'}
              width={'99%'}
            />
            {showVisibilityIconForReset ? (
              <img
                src={visibilityIcon}
                className="closeeyeicon"
                onClick={() => {
                  setShowVisibilityIconForReset(!showVisibilityIconForReset)
                  setShowResetPassword(
                    showResetPassword == 'Password' ? 'text' : 'Password',
                  )
                }}
                style={{ marginTop: '8px' }}
              />
            ) : (
              <img
                src={OpenEye}
                className="openeyeicon"
                onClick={() => {
                  setShowVisibilityIconForReset(!showVisibilityIconForReset)
                  setShowResetPassword(
                    showResetPassword == 'Password' ? 'text' : 'Password',
                  )
                }}
                style={{ marginTop: '8px' }}
              />
            )}
          </InputWrapper>
          <MainRoundedButton
            text={strings.resetPassword}
            margin={'24px 0 8px 0'}
            onClick={(e) => resetPassword(e)}
          />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  )
}

export default ResetPasswordPopUp
